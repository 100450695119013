body {
    font-family: 'Open Sans', sans-serif;
    color: #5d5d5d;
}
.typing-font {
    font-family: 'Space Mono', monospace;
}
.display-1 {
    /* font-family: 'Cormorant Garamond', serif; */
    margin-top: 2em;
    font-size: 2.5em;
    line-height: 40px;
}
.hero {
    /* width: 100vw;
    height: 100vh; */
    /* margin-top: 72px; */
    /* margin-bottom: 2.5em; */
    color: #5d5d5d;
    height: 100vh;
    /* width: 100vw; */

    /* flexbox */
    display: flex;
    /* justify-content: center; */
    align-items: center;

    /* text-align: center; */

    /* Background styles */
    /* linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)), */
    /* background-image:  url(https://images.unsplash.com/photo-1528484701073-2b22dc76649e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2089&q=80); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;


    /* transition: border-color 240ms ease-in-out;
    transition-property: border-color;
    transition-duration: 240ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s; */
}
.hero-title {
    margin-bottom: 10px;
}
.hero-inner {
    /* margin-top: 1.25rem; */
}
.hero-description {
    font-size: 1.5rem;
}
.hero-space {
    background-color: rgba(250,250,250);
    animation: caret 1s steps(1) infinite;
}
.typing-inline {
    display: inline;
}
.call-to-action {
    color: white;
    background-color: #21ce99;
    
    /* Border styles */
    /* border: 3px solid white; */
}
.btn-rounded {
    padding: 1em;
    border-radius: 50px;
}

.call-to-action:hover {
    color: white;
    background-color: #01ae79;
}

.message {
    padding: 200px 0;
    margin: 0 auto;
    text-align: center;
}
@media only screen and (min-width: 767px) {
    .hero-description {
        font-size: 2rem;
    }
}
@media only screen and (min-width: 1024px) {
    .hero-description {
        font-size: 2.7rem;
    }
}
@media only screen and (min-width: 1220px) {
    .hero-description {
        font-size: 3rem;
    }
    .display-1 {
        /* font-family: 'Cormorant Garamond', serif; */
        margin-top: 2em;
        font-size: 4.5em;
        line-height: 80px;
    }
}