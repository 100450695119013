.navbar {
    -webkit-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.navbar-brand {
    padding: 0 5px;
}

.active {
    padding-bottom: 5%;
    border-bottom: 2px black solid;
}
.cursor-pointer {
    cursor: pointer;
}
.green-theme {
    background-color: #21ce99;
}
.purple-theme {
    background-color: #836EEF;
}
.blue-theme {
    background-color: #196DEA;
}
.dropdown-item {
    height: 50px;
}
@media only screen and (max-width: 991px) {
    .nav-item {
        text-align: right;
    }
    .active {
        padding-bottom: 1%;
    }
}
@media only screen and (min-width: 992px) {
    .dropdown-item {
        height: 100px;
    }
}
body {
    font-family: 'Open Sans', sans-serif;
    color: #5d5d5d;
}
.typing-font {
    font-family: 'Space Mono', monospace;
}
.display-1 {
    /* font-family: 'Cormorant Garamond', serif; */
    margin-top: 2em;
    font-size: 2.5em;
    line-height: 40px;
}
.hero {
    /* width: 100vw;
    height: 100vh; */
    /* margin-top: 72px; */
    /* margin-bottom: 2.5em; */
    color: #5d5d5d;
    height: 100vh;
    /* width: 100vw; */

    /* flexbox */
    display: flex;
    /* justify-content: center; */
    align-items: center;

    /* text-align: center; */

    /* Background styles */
    /* linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)), */
    /* background-image:  url(https://images.unsplash.com/photo-1528484701073-2b22dc76649e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2089&q=80); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;


    /* transition: border-color 240ms ease-in-out;
    transition-property: border-color;
    transition-duration: 240ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s; */
}
.hero-title {
    margin-bottom: 10px;
}
.hero-inner {
    /* margin-top: 1.25rem; */
}
.hero-description {
    font-size: 1.5rem;
}
.hero-space {
    background-color: rgba(250,250,250);
    -webkit-animation: caret 1s steps(1) infinite;
            animation: caret 1s steps(1) infinite;
}
.typing-inline {
    display: inline;
}
.call-to-action {
    color: white;
    background-color: #21ce99;
    
    /* Border styles */
    /* border: 3px solid white; */
}
.btn-rounded {
    padding: 1em;
    border-radius: 50px;
}

.call-to-action:hover {
    color: white;
    background-color: #01ae79;
}

.message {
    padding: 200px 0;
    margin: 0 auto;
    text-align: center;
}
@media only screen and (min-width: 767px) {
    .hero-description {
        font-size: 2rem;
    }
}
@media only screen and (min-width: 1024px) {
    .hero-description {
        font-size: 2.7rem;
    }
}
@media only screen and (min-width: 1220px) {
    .hero-description {
        font-size: 3rem;
    }
    .display-1 {
        /* font-family: 'Cormorant Garamond', serif; */
        margin-top: 2em;
        font-size: 4.5em;
        line-height: 80px;
    }
}
.project-skills {
    display: flex;
    flex-wrap: wrap;
}

.project-skill {
    border-radius: 4px;
    padding: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
    /* font-family: 'Camphor', Open Sans, Segoe UI, sans-serif; */
    font-size: 0.85em;
    /* color: #5d5d5d; */
}
.project-skill.green-border {
    border: solid 2px #21ce99;
}
.project-skill.purple-border {
    border: solid 2px #836EEF;
}
.project-skill.blue-border {
    border: solid 2px #196DEA;
}

a {
    font-weight: bold;
}
a:hover {
    text-decoration: none;
    color: #ffffff;
}
.project-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.project-link {
    margin-right: 20px;
    margin-top: 20px;
    white-space: nowrap;
    display: inline-block;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
      0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    letter-spacing: 0.025em;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    font-size: 0.9em;
    font-weight: 600;
    box-sizing: border-box;
    color: #fff;
}
.project-link.green-background {
    background-color: #21ce99;
}
.project-link.purple-background {
    background-color: #836EEF;
}
.project-link.blue-background {
    background-color: #196DEA;
}
.project-link:hover {
            -webkit-transform: translateY(-1px);
                    transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
              0 3px 6px rgba(0, 0, 0, 0.08);
}
.project-link.secondary.green-font {
    background: #fff;
    color: #21ce99;
}
.project-link.secondary.purple-font {
    background: #fff;
    color: #836EEF;
}
.project-link.secondary.blue-font {
    background: #fff;
    color: #196DEA;
}
.project-link.secondary:hover {
    background: #fff;
}
i.fa-rocket, i.fa-github {
    font-size: 1.2em;
}
.project-card {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 60px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
      0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    margin-bottom: 30px;
}
.project-image {
    display: none;
}
.project-background {
    display: none;
}
.transparent-background:after {
    display: none;
}
.project-content {
    width: 100%;
    padding: 35px;
    box-sizing: border-box;
}
h3 {
    font-weight: 500;
    font-size: 1.8em;
    line-height: 38px;
    margin-top: 0;
    margin-bottom: 35px;
}
p {
    margin-bottom: 20px;
    line-height: 27px;
    /* color: #5d5d5d; */
}
@media only screen and (min-width: 1024px) {
    .project-card {
        margin-bottom: 60px;
    }
    .project-image {
        display: block;
        width: 83%;
        position: absolute !important;
        top: 0;
        left: 0;
    }
    .transparent-background:after {
        content: " ";
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
    }
    .project-background {
        display: block;
        position: absolute;
        top: -1px;
        right: -1px;
        height: 101%;
        width: 112%;
        background: url(/static/media/project-background.eb5947c6.png);
        background-size: cover;
        background-position: left;
    }
    .project-content {
        padding: 60px;
        width: 75%;
        z-index: 2;
    }
}
@media only screen and (min-width: 1200px) {
    .project-image {
        width: 60%;
    }
    .project-content {
        width: 67%;
    }
    .project-background {
        width: 98%;
    }
}
/* Because the child element (project card) creates a
   margin on the bottom outside of the parent div, this
   forces the child margin to be inside the parent div */
#projects {
    display: inline-block;
}
.contact-space {
    width: 40%;
    margin: 0 auto;
}
i.fa-linkedin, i.fa-github-square {
    margin: 0;
    font-size: 3em;
}
i.fa-paper-plane {
    font-size: 1.8em;
}
i.fa-paper-plane:hover {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    color: black;
}
.height-spacing {
    line-height: 150px;
}
.social-media {
    padding: 0;
}
.social-media:hover {
    color: black;
}
/* project-link classname in projectlinks.css file */
@media only screen and (min-width: 768px) {
    .contact-space {
        width: 20%;
    }
}
.footer {
    color: #9d9d9d;
    margin: 0 auto;
    font-size: 0.8em;
    line-height: 1.5em;
    text-align: center;
    padding-top: 150px;
    padding-bottom: 25px;
  }
.section-title {
    font-weight: 500;
    font-size: 2.5em;
    text-align: center;
    padding-top: 50px;
    /* margin-top: 50px; */
    margin-bottom: 80px;
  
}
.section-title.green-underline:after {
    content: ' ';
    border: 0.3rem solid #21ce99;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    width: 1em;
}
.section-title.purple-underline:after {
    content: ' ';
    border: 0.3rem solid #836EEF;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    width: 1em;
}
.section-title.blue-underline:after {
    content: ' ';
    border: 0.3rem solid #196DEA;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    width: 1em;
}

.green {
    color: #21ce99;
}

.purple {
    color: #836EEF;
}
.blue {
    color: #196DEA;
}
